.threeColumnTable {
	display: grid;
	grid-template-columns: 25% 25% 50%;
	margin-bottom: var(--spacing-paragraph);
	position: relative;
}

.columnHeader {
	background-color: #fff;
	border-bottom: 1px solid var(--color-divider);
	font-size: var(--textsize-xs);
	font-weight: 600;
	padding: var(--spacing-micro) 0;
	padding-right: var(--spacing-micro);
	position: sticky;
	top: 0;

	&:nth-child(3n) {
		padding-right: 0;
	}
}

.columnRow {
	border-bottom: 1px solid var(--color-divider);
	font-size: var(--textsize-xs);
	overflow-wrap: break-word;
	padding: var(--spacing-micro) 0;
	padding-right: var(--spacing-micro);

	&:nth-child(3n) {
		overflow-wrap: normal;
		padding-right: 0;
	}
}
