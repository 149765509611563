@use 'global-styles/marble/base/base.scss';

.sponsorLogoRow {
	align-items: center;
	display: flex;
	gap: var(--spacing-xxs);
}

.sponsorLogo {
	flex: 1;
}
